<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">
        {{ this.$route.meta.subtitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        transition="slide-y-transition"
        bottom
        dark
        content-class="actions"
        offset-y
        :min-width="$vuetify.breakpoint.smAndUp ? 700 : 450"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            rounded
            class="mx-1 hidden-sm-and-down"
            color="primary"
            dark
            v-on="on"
            >Akcije</v-btn
          >
          <v-btn
            small
            rounded
            class="mx-1 hidden-md-and-up"
            color="primary"
            dark
            v-on="on"
          >
            <v-icon dark>more_vert</v-icon>
          </v-btn>
        </template>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list>
                <v-list-item key="1" @click="dialog = true">
                  <v-list-item-title
                    >Kreiraj uplatnice za članarinu</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="2" @click="dialogAccepted = true">
                  <v-list-item-title
                    >Kreiraj račune za prihvaćene ponude</v-list-item-title
                  >
                </v-list-item>
               <!-- <v-list-item key="3" @click="dialogPaid = true">
                  <v-list-item-title
                    >Kreiraj račune za plaćanja po ponudi</v-list-item-title
                  >
                </v-list-item> -->
                <v-list-item key="7" @click="dialogIndPay = true">
                  <v-list-item-title>
                    Kreiraj račune na temelju uplatnica
                  </v-list-item-title>
                </v-list-item>
                <v-list-item key="15" @click="dialogEpi = true">
                  <v-list-item-title>
                    Kreiraj račune za predujam za događaje
                  </v-list-item-title>
                </v-list-item>
                <v-list-item key="16" @click="dialogEi = true">
                  <v-list-item-title>
                    Kreiraj račune za događaje
                  </v-list-item-title>
                </v-list-item>
                <v-list-item key="4" @click="downloadInvoice">
                  <v-list-item-action
                    ><v-icon>get_app</v-icon></v-list-item-action
                  >
                  <v-list-item-title>Preuzmi odabrane</v-list-item-title>
                </v-list-item>
                <v-list-item key="5" @click="sendInvoiceDialog = true">
                  <v-list-item-action
                    ><v-icon color="secondary"
                      >email</v-icon
                    ></v-list-item-action
                  >
                  <v-list-item-title>Pošalji odabrane e-račune</v-list-item-title>
                </v-list-item>
                <v-list-item key="20" @click="cancelSendInvoiceDialog = true">
                  <v-list-item-action
                    ><v-icon color="error"
                      >cancel</v-icon
                    ></v-list-item-action
                  >
                  <v-list-item-title>Otkaži slanje odabranih e-računa</v-list-item-title>
                </v-list-item>
                <v-list-item key="6" @click="sendInvoiceAllDialog = true">
                  <v-list-item-action
                    ><v-icon color="secondary"
                      >all_inbox</v-icon
                    ></v-list-item-action
                  >
                  <v-list-item-title>Pošalji sve na e-mail</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list>
                <v-list-item key="8" to="/paymentlist/0">
                  <v-list-item-action
                    ><v-icon color="error">link_off</v-icon></v-list-item-action
                  >
                  <v-list-item-title
                    >Prikaži nepovezana plaćanja</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="9" to="/paymentlist/-1">
                  <v-list-item-action
                    ><v-icon color="success">link</v-icon></v-list-item-action
                  >
                  <v-list-item-title>Prikaži sva plaćanja</v-list-item-title>
                </v-list-item>
                <v-list-item key="14" to="/unpaidinvoicelist">
                  <v-list-item-action
                    ><v-icon color="error"
                      >money_off</v-icon
                    ></v-list-item-action
                  >
                  <v-list-item-title
                    >Prikaži neplaćene račune</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="10" @click="changeStatus(1)">
                  <v-list-item-title
                    >Označi odabrane račune kao poslane</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="11" @click="changeStatus(2)">
                  <v-list-item-title
                    >Označi odabrane račune kao prihvaćene</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="12" @click="changeStatus(0)">
                  <v-list-item-title
                    >Označi odabrane račune kao kreirane</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="13" @click="dialogCancelInvoice = true">
                  <v-list-item-action
                    ><v-icon color="error">cancel</v-icon></v-list-item-action
                  >
                  <v-list-item-title
                    >Storniraj odabrane račune</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="17" @click="dialogSendAgain = true">
                  <v-list-item-action
                    ><v-icon color="secondary">all_inbox</v-icon></v-list-item-action
                  >
                  <v-list-item-title
                    >Pošalji podsjetnik za uplatnice</v-list-item-title
                  >
                </v-list-item>
                <v-list-item key="28" @click="deleteInvoiceDialog = true">
                  <v-list-item-action>
                    <v-icon color="error">delete_forever</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Briši račun</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-menu>
      <v-btn
        small
        dark
        rounded
        class="mx-1 hidden-sm-and-down"
        color="accent"
        @click="
          dialogBs = true;
          statementFile = [];
          statementLoadOK = 0;
          statementLoadFailed = 0;
        "
        >UČITAJ IZVODE</v-btn
      >

      <v-btn
        small
        dark
        fab
        class="mx-1 hidden-md-and-up"
        color="primary"
        @click="dialogBs = true"
      >
        <v-icon dark>receipt</v-icon>
      </v-btn>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
      <v-btn
        small
        fab
        dark
        class="mx-1"
        color="accent"
        :to="{ path: '/invoiceedit/-1' }"
      >
        <v-icon dark>add</v-icon>
      </v-btn>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>Odaberite godinu za članarinu</v-card-title>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="generateMembershipInvoices"
              >KREIRAJ RAČUNE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogIndPay" width="500">
        <v-card>
          <v-card-title>Odaberite godinu za članarinu</v-card-title>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="createInvoicesIndMemb"
              >KREIRAJ RAČUNE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogIndPay = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEpi" width="500">
        <v-card>
          <v-card-title>Odaberite događaj</v-card-title>
          <v-card-text>
            <v-select
              :items="events"
              v-model="selectedEvent"
              item-value="id"
              item-text="name_hr"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="createPInvoicesE"
              >KREIRAJ RAČUNE ZA PREDUJAM</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogEpi = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEi" width="500">
        <v-card>
          <v-card-title>Odaberite događaj</v-card-title>
          <v-card-text>
            <v-select
              :items="events"
              v-model="selectedEvent"
              item-value="id"
              item-text="name_hr"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="createInvoicesE"
              >KREIRAJ RAČUNE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogEi = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogPaid" width="500">
        <v-card>
          <v-card-title>Odaberite godinu za članarinu</v-card-title>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="createInvoicesPaidQuote"
              >KREIRAJ RAČUNE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogPaid = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAccepted" width="500">
        <v-card>
          <v-card-title>Odaberite godinu za članarinu</v-card-title>
          <v-card-text>
            <v-select :items="yearList" v-model="year"></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="createInvoicesAcceptedQuote"
              >KREIRAJ RAČUNE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogAccepted = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogBs" width="500">
        <v-card>
          <v-card-title>Odaberite bankovne izvode</v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col xs="12">
                  <v-file-input
                    label="Bankovni izvodi u XML formatu"
                    v-model="statementFile"
                    multiple
                    @change="
                      statementLoadOK = 0;
                      statementLoadFailed = 0;
                    "
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col xs="3">
                  <v-progress-circular
                    :rotate="-90"
                    :size="80"
                    :width="15"
                    :value="
                      Math.round(
                        statementFile.length > 0
                          ? ((statementLoadOK + statementLoadFailed) /
                              statementFile.length) *
                              100
                          : 0
                      )
                    "
                    color="primary"
                  >
                    {{
                      Math.round(
                        statementFile.length > 0
                          ? ((statementLoadOK + statementLoadFailed) /
                              statementFile.length) *
                              100
                          : 0
                      )
                    }}
                  </v-progress-circular>
                </v-col>
                <v-col xs="9">
                  uspješno: {{ this.statementLoadOK }}, neuspješno:
                  {{ this.statementLoadFailed }}, ukupno:
                  {{ this.statementFile.length }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              @click="loadBankStatement"
              >UČITAJ IZVODE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogBs = false"
              >ZATVORI PROZOR</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              small
              dark
              rounded
              icon
              class="mx-1"
              color="error"
              to="/paymentlist/0"
              @click="dialogBs = false"
              ><v-icon>link_off</v-icon></v-btn
            >
            <v-btn
              small
              dark
              rounded
              icon
              class="mx-1"
              color="success"
              to="/paymentlist/-1"
              @click="dialogBs = false"
              ><v-icon>link</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sendInvoiceDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite poslati odabrane račune?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="sendInvoice"
              >POŠALJI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="sendInvoiceDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="cancelSendInvoiceDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite otkazati slanje odabranih računa?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="cancelSendInvoice"
              >OTKAŽI SLANJE</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="cancelSendInvoiceDialog = false"
              >ODUSTANI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="sendInvoiceAllDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite poslati sve račune?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="sendInvoiceAll"
              >POŠALJI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="sendInvoiceAllDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCancelInvoice" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite stornirati odabrane račune?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="
                changeStatus(3);
                dialogCancelInvoice = false;
              "
              >STORNIRAJ</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogCancelInvoice = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSendAgain" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite poslati e-mail s uplatnicom svima koji još nisu platili članarinu?</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="sendAgain"
              >POŠALJI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="dialogSendAgain = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteInvoiceDialog" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >Jeste li sigurni da želite obrisati odabrani račun?<br/>Odabrani račun mora biti zadnji račun s najvećim brojem i u statusu "Kreiran"!</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="error"
              @click="deleteInvoice"
              >BRIŠI</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="deleteInvoiceDialog = false"
              >OTKAŽI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
        :class="{
          'loading-visible': this.loading,
          'loading-hidden': !this.loading
        }"
      ></v-progress-circular>
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <v-tabs v-model="tabs" show-arrows>
              <v-tab>UPLATNICE</v-tab>
              <v-tab>IND.ČL. PODUZEĆE UPL.</v-tab>
              <v-tab>IND.ČL. PODUZEĆE PON.</v-tab>
              <v-tab>KOLEKTIVNE ČL.</v-tab>
              <v-tab>MEĐUNARODNE IND.ČL.</v-tab>
              <v-tab>MEĐUNARODNE KOL.ČL.</v-tab>
              <v-tab>DOGAĐAJI</v-tab>
              <v-tab>SPONZORI</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList1"
                  ref="invoiceList1"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=1"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList2"
                  ref="invoiceList2"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=3,13"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: 'primary'
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList3"
                  ref="invoiceList3"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=2,12"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList4"
                  ref="invoiceList4"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=4,14"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList5"
                  ref="invoiceList5"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=5,15"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceList6"
                  ref="invoiceList6"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php?type=6,16"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceListE"
                  ref="invoiceListE"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  :selectFilter="events"
                  selectFilterId="id"
                  selectFilterText="name_hr"
                  selectFilterLabel="Događaj"
                  selectFilterName="event"
                  :typeFilter="types"
                  typeFilterId="id"
                  typeFilterText="name"
                  typeFilterLabel="Vrsta"
                  typeFilterName="type"
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
              <v-tab-item
                transition="scroll-y-transition"
                reverse-transition="scroll-y-transition"
              >
                <InteractiveTable
                  id="invoiceListS"
                  ref="invoiceListS"
                  class="xl-table"
                  :headers="[
                    { text: 'Broj', value: 'invoice_num' },
                    { text: 'Platitelj', value: 'recipient', width: 250 },
                    { text: 'Status', value: 'status' },
                    { text: 'E-račun', value: 'estatus'},
                    { text: 'Proces', value: 'pstatus'},
                    { text: 'Iznos', value: 'amount_total', align: 'end' },
                    { text: 'Kreiran', value: 'created' },
                    { text: 'Poziv na broj', value: 'irn' },
                    { text: 'Izvod', value: 'statement_num' },
                    { text: 'Plaćeno', value: 'paid' }
                  ]"
                  apiLoad="invoice/invoices.php"
                  :actions="[
                    {
                      id: '1',
                      name: 'Uredi račun',
                      path: '/invoiceedit/#id#',
                      icon: 'edit',
                      color: 'secondary'
                    },
                    {
                      id: '2',
                      name: 'Plaćanja računa',
                      path: '/paymentlist/#id#',
                      icon: 'payment',
                      color: ['error', 'orange', 'primary']
                    },
                    {
                      id: '3',
                      name: 'Preuzmi PDF računa',
                      apiDownloadCall: 'invoice/invoice.php?format=pdf',
                      icon: 'picture_as_pdf',
                      color: 'error'
                    }
                  ]"
                  defaultFilter="recipient"
                  multiSelect
                  :statusColorFunc="statusColorFunc"
                  defaultSort="invoice_num"
                  defaultSortDesc
                  :selectFilter="events"
                  selectFilterId="id"
                  selectFilterText="name_hr"
                  selectFilterLabel="Događaj"
                  selectFilterName="event"
                  :typeFilter="typesS"
                  typeFilterId="id"
                  typeFilterText="name"
                  typeFilterLabel="Vrsta"
                  typeFilterName="type"
                  yearFilter
                ></InteractiveTable>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        right
        :timeout="3000"
        :color="this.snackbarType === 0 ? 'success' : 'error'"
        >{{ this.message }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "InvoiceList",
  data() {
    return {
      dialog: false,
      year: new Date().getFullYear(),
      yearList: [],
      loading: false,
      snackbar: false,
      snackbarType: 0,
      message: "",
      dialogBs: false,
      statementFile: [],
      dialogIndPay: false,
      statementLoadOK: 0,
      statementLoadFailed: 0,
      tabs: null,
      dialogPaid: false,
      dialogAccepted: false,
      sendInvoiceDialog: false,
      cancelSendInvoiceDialog: false,
      sendInvoiceAllDialog: false,
      dialogCancelInvoice: false,
      dialogSendAgain: false,
      deleteInvoiceDialog: false,
      dialogEpi: false,
      dialogEi: false,
      events: [],
      types: [
        { id: "80,90", name: "Prikaži sve" },
        { id: "90", name: "Samo računi" },
        { id: "80", name: "Samo računi za predujam" }
      ],
      typesS: [
        { id: "100,110", name: "Prikaži sve" },
        { id: "110", name: "Samo računi" },
        { id: "100", name: "Samo računi za predujam" }
      ],
      selectedEvent: null
    };
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.invoiceListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      if (this.$refs.invoiceList1) this.$refs.invoiceList1.reload();
      if (this.$refs.invoiceList2) this.$refs.invoiceList2.reload();
      if (this.$refs.invoiceList3) this.$refs.invoiceList3.reload();
      if (this.$refs.invoiceList4) this.$refs.invoiceList4.reload();
      if (this.$refs.invoiceList5) this.$refs.invoiceList5.reload();
      if (this.$refs.invoiceList6) this.$refs.invoiceList6.reload();
      if (this.$refs.invoiceListE) this.$refs.invoiceListE.reload();
      if (this.$refs.invoiceListS) this.$refs.invoiceListS.reload();
      this.$store.dispatch("invoice_list_refreshed");
    },
    generateMembershipInvoices() {
      this.dialog = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "membership_invoice",
            year: this.year
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa: " + error;
          this.snackbar = true;
        });
    },
    sendInvoice() {
      this.sendInvoiceDialog = false;
      var idata = [];
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.invoiceList1) {
            selected = this.$refs.invoiceList1.selected;
            this.$refs.invoiceList1.$refs.dataTable.selection = [];
          }
          break;
        case 1:
          if (this.$refs.invoiceList2) {
            selected = this.$refs.invoiceList2.selected;
            this.$refs.invoiceList2.$refs.dataTable.selection = [];
          }
          break;
        case 2:
          if (this.$refs.invoiceList3) {
            selected = this.$refs.invoiceList3.selected;
            this.$refs.invoiceList3.$refs.dataTable.selection = [];
          }
          break;
        case 3:
          if (this.$refs.invoiceList4) {
            selected = this.$refs.invoiceList4.selected;
            this.$refs.invoiceList4.$refs.dataTable.selection = [];
          }
          break;
        case 4:
          if (this.$refs.invoiceList5) {
            selected = this.$refs.invoiceList5.selected;
            this.$refs.invoiceList5.$refs.dataTable.selection = [];
          }
          break;
        case 5:
          if (this.$refs.invoiceList6) {
            selected = this.$refs.invoiceList6.selected;
            this.$refs.invoiceList6.$refs.dataTable.selection = [];
          }
          break;
        case 6:
          if (this.$refs.invoiceListE) {
            selected = this.$refs.invoiceListE.selected;
            this.$refs.invoiceListE.$refs.dataTable.selection = [];
          }
          break;
        case 7:
          if (this.$refs.invoiceListS) {
            selected = this.$refs.invoiceListS.selected;
            this.$refs.invoiceListS.$refs.dataTable.selection = [];
          }
          break;
      }
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          // data needed to generate mail for user or company invoice
          idata.push({
            id: element.id // id of invoice
          });
        }

        // generate mail collection for invoices on server
        this.$http
          .post("invoice/invoicecollection.php", {
            params: {
              type: "einvoice",
              invoicedata: idata
            }
          })
          .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message =
              "Odabrani e-računi su spremni za slanje. Bit će poslani u pozadini.";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u označavanju e-računa za slanje";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u pripremi e-računa za slanje: " + error;
          this.snackbar = true;
        });
      }
    },
    cancelSendInvoice() {
      this.cancelSendInvoiceDialog = false;
      var idata = [];
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.invoiceList1) {
            selected = this.$refs.invoiceList1.selected;
            this.$refs.invoiceList1.$refs.dataTable.selection = [];
          }
          break;
        case 1:
          if (this.$refs.invoiceList2) {
            selected = this.$refs.invoiceList2.selected;
            this.$refs.invoiceList2.$refs.dataTable.selection = [];
          }
          break;
        case 2:
          if (this.$refs.invoiceList3) {
            selected = this.$refs.invoiceList3.selected;
            this.$refs.invoiceList3.$refs.dataTable.selection = [];
          }
          break;
        case 3:
          if (this.$refs.invoiceList4) {
            selected = this.$refs.invoiceList4.selected;
            this.$refs.invoiceList4.$refs.dataTable.selection = [];
          }
          break;
        case 4:
          if (this.$refs.invoiceList5) {
            selected = this.$refs.invoiceList5.selected;
            this.$refs.invoiceList5.$refs.dataTable.selection = [];
          }
          break;
        case 5:
          if (this.$refs.invoiceList6) {
            selected = this.$refs.invoiceList6.selected;
            this.$refs.invoiceList6.$refs.dataTable.selection = [];
          }
          break;
        case 6:
          if (this.$refs.invoiceListE) {
            selected = this.$refs.invoiceListE.selected;
            this.$refs.invoiceListE.$refs.dataTable.selection = [];
          }
          break;
        case 7:
          if (this.$refs.invoiceListS) {
            selected = this.$refs.invoiceListS.selected;
            this.$refs.invoiceListS.$refs.dataTable.selection = [];
          }
          break;
      }
      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          
          idata.push({
            id: element.id // id of invoice
          });
        }

        this.$http
          .post("invoice/invoicecollection.php", {
            params: {
              type: "einvoice_cancel",
              invoicedata: idata
            }
          })
          .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message =
              "Odabrani e-računi su spremni za otkazivanje slanja. Slanje će biti otkazano u pozadini.";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u označavanju e-računa za otkazivanje slanja";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u pripremi e-računa za otkazivanje slanja: " + error;
          this.snackbar = true;
        });
      }
    },
    sendInvoiceAll() {
      // generate mail collection for all unsent invoices
      this.sendInvoiceAllDialog = false;
      this.loading = true;
      this.$http
        .post("mail/mailcollection.php", {
          params: {
            type: "invoiceall"
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message =
              "E-mail poruke za slanje su uspješno generirane. E-mailovi će se poslati u pozadini.";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju e-mailova za slanje";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u pripremi e-mailova za slanje: " + error;
          this.snackbar = true;
        });
    },
    downloadInvoice() {
      var idata = [];
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.invoiceList1)
            selected = this.$refs.invoiceList1.selected;
          break;
        case 1:
          if (this.$refs.invoiceList2)
            selected = this.$refs.invoiceList2.selected;
          break;
        case 2:
          if (this.$refs.invoiceList3)
            selected = this.$refs.invoiceList3.selected;
          break;
        case 3:
          if (this.$refs.invoiceList4)
            selected = this.$refs.invoiceList4.selected;
          break;
        case 4:
          if (this.$refs.invoiceList5)
            selected = this.$refs.invoiceList5.selected;
          break;
        case 5:
          if (this.$refs.invoiceList6)
            selected = this.$refs.invoiceList6.selected;
          break;
        case 6:
          if (this.$refs.invoiceListE)
            selected = this.$refs.invoiceListE.selected;
          break;
        case 7:
          if (this.$refs.invoiceListS)
            selected = this.$refs.invoiceListS.selected;
          break;
      }

      if (selected && selected.length > 0) {
        this.downloadInvoiceRecursive(selected, 0);
      }
    },
    downloadInvoiceRecursive(selected, i) {
      if (i < selected.length) {
        var element = selected[i];

        this.$http
          .get("invoice/invoice.php", {
            params: {
              format: "pdf",
              id: element.id
            },
            responseType: "blob"
          })
          .then(response => {
            this.loading = false;
            const fileName = response.headers["x-suggested-filename"];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            i = i + 1;
            this.downloadInvoiceRecursive(selected, i);
          });
      }
    },
    loadBankStatement() {
      if (this.statementFile.length > 0) {
        this.statementLoadOK = 0;
        this.statementLoadFailed = 0;
        for (var i = 0; i < this.statementFile.length; i++) {
          this.statementFile[i].arrayBuffer().then(buffer => {
            this.$http
              .post("payment/paymentcollection.php", {
                params: {
                  type: "statement_file",
                  statement_file: Array.from(new Uint8Array(buffer))
                }
              })
              .then(response => {
                this.statementLoadOK++;
              })
              .catch(error => {
                this.statementLoadFailed++;
              });
          });
        }
      }
    },
    createInvoicesIndMemb() {
      // generate invoices for individual membership for which company made payment
      this.dialogIndPay = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "membership_invoice_ind_paid",
            year: this.year
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa: " + error;
          this.snackbar = true;
        });
    },
    createInvoicesPaidQuote() {
      // generate invoices for paid quotes
      this.dialogPaid = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "membership_invoice_paid_quote",
            year: this.year
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa: " + error;
          this.snackbar = true;
        });
    },
    createInvoicesAcceptedQuote() {
      // generate invoices for accepted quotes
      this.dialogAccepted = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "membership_invoice_accepted_quote",
            year: this.year
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa: " + error;
          this.snackbar = true;
        });
    },
    createPInvoicesE() {
      // generate pre invoices for events
      this.dialogEpi = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "event_pinvoice",
            event: this.selectedEvent
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi za predujam su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa za predujam";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa za predujam: " + error;
          this.snackbar = true;
        });
    },
    createInvoicesE() {
      // generate invoices for events
      this.dialogEi = false;
      this.loading = true;
      // generate invoices on server
      this.$http
        .post("invoice/invoicecollection.php", {
          params: {
            type: "event_invoice",
            event: this.selectedEvent
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message = "Računi su uspješno generirani";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju računa";
          }
          this.snackbar = true;
          this.reload();
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u generiranju računa: " + error;
          this.snackbar = true;
        });
    },
    sendAgain() {
      // send again mails for private membership to those who didn't pay
      this.loading = true;
      if (this.snackbar) this.snackbar = false;
      this.snackbarType = 1;
      this.message = "Pričekajte nekoliko sekundi za generiranje podsjetnika...";
      this.snackbar = true;
      this.$http
        .post("mail/mailcollection.php", {
          params: {
            type: "resend"
          }
        })
        .then(response => {
          this.dialogSendAgain = false;
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.snackbarType = 0;
            this.message =
              "E-mail poruke za slanje su uspješno generirane. E-mailovi će se poslati u pozadini.";
          } else {
            this.snackbarType = 1;
            this.message = "Greška u generiranju e-mailova za slanje";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.dialogSendAgain = false;
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u pripremi e-mailova za slanje: " + error;
          this.snackbar = true;
        });
    },
    statusColorFunc(status) {
      var col = "white";
      switch (status) {
        case "KREIRAN":
          col = "secondary";
          break;
        case "POSLAN":
          col = "orange";
          break;
        case "PRIHVAĆEN":
          col = "primary";
          break;
        case "STORNIRAN":
          col = "error";
          break;
        case "ZA SLANJE":
          col = "grey";
          break;
        case "U PRIPREMI":
          col = "lightblue";
          break;
        case "U VALIDACIJI":
          col = "secondary";
          break;
        case "DOSTAVLJEN":
          col = "green";
          break;
        case "OTKAZAN":
          col = "error";
          break;
        case "NEUSPJELO":
          col = "error";
          break;
        case "ODBIJEN":
          col = "error";
          break;
        case "PLAĆEN":
          col = "primary"
          break;
        case "DJ. PLAĆEN":
          col = "warning";
        break;
        case "PREUZET":
          col = "lightblue";
        break;
        case "ZAPRIMLJEN":
          col = "secondary";
        break;
        case "ZA OTKAZ":
          col = "grey";
          break;
        case "?":
          col = "grey";
          break;
      }
      return col;
    },
    deleteInvoice() {
      this.deleteInvoiceDialog = false;
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.invoiceList1) {
            selected = this.$refs.invoiceList1.selected;
            this.$refs.invoiceList1.selected = [];
          }
          break;
        case 1:
          if (this.$refs.invoiceList2) {
            selected = this.$refs.invoiceList2.selected;
            this.$refs.invoiceList2.selected = [];
          }
          break;
        case 2:
          if (this.$refs.invoiceList3) {
            selected = this.$refs.invoiceList3.selected;
            this.$refs.invoiceList3.selected = [];
          }
          break;
        case 3:
          if (this.$refs.invoiceList4) {
            selected = this.$refs.invoiceList4.selected;
            this.$refs.invoiceList4.selected = [];
          }
          break;
        case 4:
          if (this.$refs.invoiceList5) {
            selected = this.$refs.invoiceList5.selected;
            this.$refs.invoiceList5.selected = [];
          }
          break;
        case 5:
          if (this.$refs.invoiceList6) {
            selected = this.$refs.invoiceList6.selected;
            this.$refs.invoiceList6.selected = [];
          }
          break;
        case 6:
          if (this.$refs.invoiceListE) {
            selected = this.$refs.invoiceListE.selected;
            this.$refs.invoiceListE.selected = [];
          }
          break;
        case 7:
          if (this.$refs.invoiceListS) {
            selected = this.$refs.invoiceListS.selected;
            this.$refs.invoiceListS.selected = [];
          }
          break;
      }
      if (selected && selected.length == 1) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          this.loading = true;
          this.$http
            .delete("invoice/invoice.php", {
              params: {
                id: element.id
              }
            })
            .then(response => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              if (response.data.success) {
                this.snackbarType = 0;
                this.message =
                  "Račun broj " +
                  response.data.invoice_num +
                  " uspješno obrisan";
              } else {
                this.snackbarType = 1;
                this.message =
                  "Greška u brisanju računa broj " + response.data.quote_num;
              }
              this.snackbar = true;
              this.reload();
            })
            .catch(error => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              this.snackbarType = 1;
              this.message = "Greška u brisanju računa: " + error;
              this.snackbar = true;
            });
        }
      }
    },
    changeStatus(status) {
      var selected = null;
      switch (this.tabs) {
        case 0:
          if (this.$refs.invoiceList1) {
            selected = this.$refs.invoiceList1.selected;
          }
          break;
        case 1:
          if (this.$refs.invoiceList2) {
            selected = this.$refs.invoiceList2.selected;
          }
          break;
        case 2:
          if (this.$refs.invoiceList3) {
            selected = this.$refs.invoiceList3.selected;
          }
          break;
        case 3:
          if (this.$refs.invoiceList4) {
            selected = this.$refs.invoiceList4.selected;
          }
          break;
        case 4:
          if (this.$refs.invoiceList5) {
            selected = this.$refs.invoiceList5.selected;
          }
          break;
        case 5:
          if (this.$refs.invoiceList6) {
            selected = this.$refs.invoiceList6.selected;
          }
          break;
        case 6:
          if (this.$refs.invoiceListE) {
            selected = this.$refs.invoiceListE.selected;
          }
          break;
        case 7:
          if (this.$refs.invoiceListS) {
            selected = this.$refs.invoiceListS.selected;
          }
          break;
      }

      if (selected && selected.length > 0) {
        for (let i = 0; i < selected.length; i++) {
          var element = selected[i];
          this.loading = true;
          this.$http
            .put("invoice/invoice.php", {
              params: {
                id: element.id,
                new_status: status
              }
            })
            .then(response => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              if (response.data.success) {
                this.snackbarType = 0;
                this.message = "Status računa uspješno izmjenjen";
              } else {
                this.snackbarType = 1;
                this.message = "Greška u promjeni statusa računa";
              }
              this.snackbar = true;
              this.reload();
            })
            .catch(error => {
              this.loading = false;
              if (this.snackbar) this.snackbar = false;
              this.snackbarType = 1;
              this.message = "Greška u promjeni statusa računa: " + error;
              this.snackbar = true;
            });
        }
      }
    }
  },
  mounted() {
    var y = new Date().getFullYear() + 1;
    for (var i = 0; i < 5; i++) {
      this.yearList.push(y - i);
    }
    // events
    var me = this;
    this.$http
      .get("event/events.php", {
        params: {}
      })
      .then(response => {
        me.events = response.data.rows;
        if (me.events.length > 0) {
          me.selectedEvent = me.events[0].id;
        }
      })
      .catch(error => {
        if (me.snackbar) me.snackbar = false;
        me.snackbarType = 1;
        me.message = "Greška u dohvaćanju događaja " + error;
        me.snackbar = true;
      });
    // reload on timer
    setInterval(this.reload, 10000);
  }
};
</script>
<style scoped>
.stype {
  width: 130px;
}
.v-progress-linear__bar,
.v-progress-linear__bar__determinate {
  transition: none;
}
.actions {
  margin-top: 10px;
  margin-right: 30px;
  background-color: #2196f3;
}
</style>
